import React, { useEffect, useRef } from "react"
import FeatureTabs from "./FeatureTabs"
import Typography from "aldoo-ra/Typography"
import MenuGroup from "aldoo-ra/MenuGroup"
import Button from "aldoo-ra/Button"
import Value from "aldoo-ra/Value"
import parse from "html-react-parser"
import ItemSlider from "./ItemSlider"

const FeaturesScroll = ({
  controller,
  showDescription = false,
  overwriteFeatures,
  scrollHeight,
  cardDistance,
}) => {
  const [platform] = Value("site_platform")

  // Get controller values from props
  const { selectedFeature, selectFeatureByID, getData } = controller || {}
  const features = overwriteFeatures ?? (getData ? getData() : [])
  const initialized = useRef(false)

  // Initialize on first render
  useEffect(() => {
    if (initialized.current || !features?.length || !selectFeatureByID) return

    // Select the first feature if none is selected yet
    if (!selectedFeature && features.length > 0) {
      selectFeatureByID(features[0].id)
    }

    initialized.current = true
  }, [features, selectedFeature, selectFeatureByID])

  // Early return if no features
  if (!features || features.length === 0) return null
  let { info } = selectedFeature || {}
  !Array.isArray(info) && info && (info = [info])

  return (
    <div className="w-full max-w-[1250px] flex flex-col items-center mx-auto">
      <div className="w-full">
        <FeatureTabs
          features={features}
          shouldCenter={true}
          selectedFeatureID={selectedFeature?.id}
          onFeatureSelect={(featureId) => {
            selectFeatureByID(featureId)
          }}
          className="w-full"
        />
      </div>

      <ItemSlider
        items={features}
        selectedItem={selectedFeature}
        onItemSelect={selectFeatureByID}
        cardDistance={cardDistance}
        targetHeight={scrollHeight}
      />

      {/* Description Section without scroll */}
      {showDescription && info && (
        <div className="w-full max-w-[700px] px-4 text-left mx-auto mt-8">
          <div>
            {info?.map((text, index) => (
              <Typography
                key={index}
                component={"div"}
                variant="body_20"
                className="w-full text-text mb-4"
              >
                {parse(text)}
              </Typography>
            ))}
          </div>

          <div className="mb-6 md:mb-16">
            <MenuGroup
              name="TryAppNow"
              renderAs="hlist"
              itemsAsLinks={false}
              styleOverrides={{
                menu_item: "mx-auto",
              }}
              itemRender={(item) => {
                const url =
                  (item?.link[0]?.parameters &&
                    item?.link[0]?.parameters[platform]) ||
                  item?.link[0]?.url

                return (
                  <Button className="w-auto h-[52px] md:h-[48px] flex items-center justify-center bg-primary hover:bg-primary_hov text-white text-[24px] md:text-[20px] font-bold rounded-full px-[16px] md:px-[18px] xl:px-[20px] transition">
                    <a href={url} target="_blank">
                      {item?.label}
                    </a>
                  </Button>
                )
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default FeaturesScroll
