import React, { useEffect, useState, useRef, useCallback } from "react"
import DynamicSVG from "aldoo-ra/DynamicSVG"
import parse from "html-react-parser"
import { motion } from "framer-motion"

const FeatureTabs = ({
  features,
  selectedFeatureID,
  onFeatureSelect,
  shouldCenter = false,
  className = "",
}) => {
  // All state declarations
  const [visibleItems, setVisibleItems] = useState(3)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [prevPosition, setPrevPosition] = useState({})
  const [lastScrollDirection, setLastScrollDirection] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)

  const getResponsiveItemWidth = () => {
    //breaks are: 768, 1080, 1280
    if (typeof window === "undefined") return 116
    const width = window.innerWidth
    if (width >= 1280) {
      // xl
      return 120
    } else if (width >= 1080) {
      // lg
      return 110
    } else if (width >= 768) {
      // md
      return 100
    } else {
      // sm and smaller
      return 90
    }
  }

  // All refs
  const containerRef = useRef(null)
  const animatedItemsRef = useRef(new Set())

  // Constants
  const itemWidth = getResponsiveItemWidth() // Width of each item
  const gap = 24 // Gap between items

  // Init active index
  useEffect(() => {
    let index = features.findIndex((f) => f.id === selectedFeatureID)
    if (index === -1) {
      index = 0
      if (features.length > 0) {
        onFeatureSelect(features[0].id)
      }
    }
    setActiveIndex(index)
  }, [features, selectedFeatureID, onFeatureSelect])

  // Initialize animation tracking
  useEffect(() => {
    if (features && features.length > 0) {
      features.forEach((feature) => {
        animatedItemsRef.current.add(feature.id)
      })
    }
  }, [features])

  // Get number of visible items based on screen size
  useEffect(() => {
    const updateVisibleItems = () => {
      const width = window.innerWidth
      if (width >= 1280) {
        // xl
        setVisibleItems(shouldCenter ? 11 : 9)
      } else if (width >= 1080) {
        // lg
        setVisibleItems(shouldCenter ? 9 : 7)
      } else if (width >= 768) {
        // md
        setVisibleItems(shouldCenter ? 7 : 5)
      } else {
        // sm and smaller
        setVisibleItems(shouldCenter ? 5 : 3)
      }
    }

    updateVisibleItems()
    window.addEventListener("resize", updateVisibleItems)
    return () => window.removeEventListener("resize", updateVisibleItems)
  }, [shouldCenter])

  // Track which items are exiting
  const [exitingItems, setExitingItems] = useState({})

  // Helper function to determine initial position for animations
  const determineInitialPosition = useCallback(
    (positionX) => {
      const sideItemCount = Math.floor(visibleItems / 2)

      // Calculate the rightmost visible position (matches the example image)
      const rightEdgePosition = sideItemCount * (itemWidth + gap)

      // If we're moving right (next), new items appear from the rightmost position + offset
      if (lastScrollDirection > 0) {
        return rightEdgePosition + 100
      }
      // If we're moving left (prev), new items appear from the leftmost position - offset
      else if (lastScrollDirection < 0) {
        const leftEdgePosition = -sideItemCount * (itemWidth + gap)
        return leftEdgePosition - 100
      }
      // Default case - no animation
      else {
        return positionX
      }
    },
    [visibleItems, itemWidth, gap, lastScrollDirection]
  )

  // Calculate slot positions for centered layout
  const getSlotPositions = useCallback(() => {
    const slots = {}
    const sideItems = Math.floor(visibleItems / 2)

    // Calculate positions from center outward
    for (let i = -sideItems; i <= sideItems; i++) {
      slots[i] = {
        x: i * (itemWidth + gap),
        opacity: Math.abs(i) === sideItems ? 0 : 1,
        scale: i === 0 ? 1 : 0.9,
      }
    }

    // Add additional off-screen positions for smoother transitions
    // Left edge
    slots[-sideItems - 1] = {
      x: (-sideItems - 1) * (itemWidth + gap),
      opacity: 0,
      scale: 0.8,
    }

    // Right edge
    slots[sideItems + 1] = {
      x: (sideItems + 1) * (itemWidth + gap),
      opacity: 0,
      scale: 0.8,
    }

    return slots
  }, [visibleItems, itemWidth, gap])

  // Function to calculate item position similar to FeaturesScroll
  const getItemPosition = useCallback(
    (index) => {
      const totalItems = features.length
      const sideItems = Math.floor(visibleItems / 2)

      // Calculate the relative position considering infinite scroll
      let position = index - activeIndex

      // Handle wrapping for infinite scroll
      if (position > sideItems) {
        // If position is too far to the right, wrap around to the left
        position -= totalItems
      } else if (position < -sideItems) {
        // If position is too far to the left, wrap around to the right
        position += totalItems
      }

      return position
    },
    [activeIndex, visibleItems, features.length]
  )

  // Get position for each feature with offset for dragging
  const getFeaturePosition = useCallback(
    (index) => {
      const rawPosition = getItemPosition(index)
      const dragOffset = isDragging ? currentOffset : 0
      const sideItemCount = Math.floor(visibleItems / 2)
      const totalItems = features.length

      // Get the feature from the index
      const feature = features[index]
      const slots = getSlotPositions()

      // Check if this item is exiting
      const isExiting = feature && exitingItems[feature.id] !== undefined
      const exitDirection = feature ? exitingItems[feature.id] : 0

      // Create a virtual position that wraps around
      let virtualPosition = rawPosition

      // Check if this item is tracked for animation
      const isTrackedForAnimation =
        feature && animatedItemsRef.current.has(feature.id)

      // Determine if the item is outside the visible range and should wrap
      const isOutsideRange = Math.abs(rawPosition) > sideItemCount

      // Items newly entering the view (from either direction)
      const isNewlyVisible =
        isOutsideRange && Math.abs(virtualPosition) <= sideItemCount

      // Set wrapping flag - true if outside range OR tracked for animation
      const isWrapping =
        isOutsideRange || isTrackedForAnimation || isNewlyVisible

      // Get the wrap direction either from last scroll or position
      let wrapDirection = lastScrollDirection
      if (wrapDirection === 0) {
        wrapDirection = rawPosition < 0 ? -1 : 1
      }

      // Calculate proper wrapped position
      if (rawPosition < -sideItemCount) {
        // Wrap from left to right
        virtualPosition = rawPosition + totalItems
      } else if (rawPosition > sideItemCount) {
        // Wrap from right to left
        virtualPosition = rawPosition - totalItems
      }

      // If after wrapping the item is still outside visible range (can happen with very few items)
      // we need to wrap again or position it properly
      if (Math.abs(virtualPosition) > sideItemCount) {
        // Instead of hiding, position it at the furthest valid slot position
        // This allows for smooth transitions
        const slotPosition =
          virtualPosition > 0 ? sideItemCount + 1 : -sideItemCount - 1

        return {
          x: slots[slotPosition].x + dragOffset,
          opacity: slots[slotPosition].opacity,
          scale: slots[slotPosition].scale,
          pointerEvents: "none",
          isWrapping,
          isExiting,
          exitDirection,
          wrapDirection: virtualPosition > 0 ? 1 : -1,
        }
      }

      // For slot positioning, clamp to valid slot indices
      const slotPosition = Math.max(
        -sideItemCount - 1,
        Math.min(sideItemCount + 1, virtualPosition)
      )

      // Return slot position with drag offset
      return {
        x: slots[slotPosition].x + dragOffset,
        opacity: slots[slotPosition].opacity,
        scale: slots[slotPosition].scale,
        pointerEvents: isDragging ? "none" : "auto",
        isWrapping,
        isExiting,
        exitDirection,
        wrapDirection,
      }
    },
    [
      getItemPosition,
      isDragging,
      currentOffset,
      visibleItems,
      features,
      lastScrollDirection,
      getSlotPositions,
      exitingItems,
    ]
  )

  // Add non-passive touch event listeners to container
  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    // Touch handlers
    const touchStartHandler = (e) => {
      if (!shouldCenter) return
      setIsDragging(true)
      setStartX(e.touches[0].clientX)
    }

    const touchMoveHandler = (e) => {
      if (!shouldCenter || !isDragging) return
      const currentX = e.touches[0].clientX
      const diff = currentX - startX
      setCurrentOffset(diff)
      e.preventDefault() // This works when listener is non-passive
    }

    const touchEndHandler = () => {
      if (!shouldCenter || !isDragging) return

      // Calculate which item should be selected based on drag distance
      const itemDistance = itemWidth + gap
      const significantSwipe = Math.abs(currentOffset) > itemDistance / 3

      if (significantSwipe) {
        // Determine swipe direction
        const direction = currentOffset > 0 ? -1 : 1 // Positive offset = swipe right (prev), negative = swipe left (next)

        // Mark appropriate items for exit animations
        const sideItemCount = Math.floor(visibleItems / 2)

        if (direction > 0) {
          // Swiping to the left (next) - leftmost item exits to the left
          const leftmostIndex =
            (activeIndex - sideItemCount + features.length) % features.length
          if (features[leftmostIndex]) {
            setExitingItems({
              [features[leftmostIndex].id]: -1, // -1 for left exit direction
            })
          }
        } else {
          // Swiping to the right (prev) - rightmost item exits to the right
          const rightmostIndex = (activeIndex + sideItemCount) % features.length
          if (features[rightmostIndex]) {
            setExitingItems({
              [features[rightmostIndex].id]: 1, // 1 for right exit direction
            })
          }
        }

        // Mark all items as needing animation
        features.forEach((feature) => {
          animatedItemsRef.current.add(feature.id)
        })

        // Save current positions before changing
        setPrevPosition(
          Object.fromEntries(features.map((_, i) => [i, getItemPosition(i)]))
        )

        // Track scroll direction
        setLastScrollDirection(direction)

        // Get new index with wrapping for infinite scroll
        const newIndex =
          (activeIndex + direction + features.length) % features.length
        setActiveIndex(newIndex)

        // Update selection immediately to prevent lag
        onFeatureSelect(features[newIndex].id)

        // Clear exiting items after animation completes
        setTimeout(() => {
          setExitingItems({})
        }, 300)
      }

      // Reset state
      setIsDragging(false)
      setCurrentOffset(0)
    }

    // Add non-passive event listeners
    container.addEventListener("touchstart", touchStartHandler, {
      passive: true,
    })
    container.addEventListener("touchmove", touchMoveHandler, {
      passive: false,
    })
    container.addEventListener("touchend", touchEndHandler, { passive: true })

    return () => {
      container.removeEventListener("touchstart", touchStartHandler)
      container.removeEventListener("touchmove", touchMoveHandler)
      container.removeEventListener("touchend", touchEndHandler)
    }
  }, [
    shouldCenter,
    isDragging,
    startX,
    currentOffset,
    features,
    activeIndex,
    itemWidth,
    gap,
    getItemPosition,
    onFeatureSelect,
    visibleItems,
  ])

  // If not centering, render original layout
  if (!shouldCenter) {
    return (
      <div
        className={`w-full overflow-x-auto lg:overflow-x-hidden overflow-y-hidden no-scrollbar mt-8 md:mt-0 ${className}`}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <div
          className="w-full inline-flex items-center justify-center gap-3 xl:gap-[clamp(0.5rem,_calc((100%_-_var(--total-width))_/_max(1,_var(--item-count)_-_1)),_1.5rem)] px-2 md:px-4 lg:px-0"
          style={{
            "--item-count": features.length,
            "--total-width": `${features.length * itemWidth}px`,
          }}
        >
          {features.map((feature) => (
            <button
              key={feature.id}
              onClick={() => onFeatureSelect(feature.id)}
              className={`group w-[${itemWidth}px] h-[146px] flex flex-col items-center justify-between pt-2 shrink-0 transition-all duration-500`}
            >
              <div
                className={`flex items-center justify-center rounded-[12px] transform transition-all duration-500 ease-in-out ${
                  selectedFeatureID === feature.id
                    ? "bg-primary w-[46px] lg:w-14 2xl:w-16 h-[46px] lg:h-14 2xl:h-16"
                    : "w-10 lg:w-12 2xl:w-14 h-10 lg:h-12 2xl:h-14 group-hover:-translate-y-1 group-hover:bg-primary_lt"
                }`}
              >
                <DynamicSVG
                  url={feature.icon}
                  className={`transition-all duration-500 ease-in-out ${
                    selectedFeatureID === feature.id
                      ? "w-[34px] lg:w-10 2xl:w-12 h-[34px] lg:h-10 2xl:h-12 text-white"
                      : "w-7 lg:w-8 2xl:w-10 h-7 lg:h-8 2xl:h-10 text-primary"
                  }`}
                />
              </div>
              <span
                className={`h-[34px] lg:h-[76px] text-primary transition-all duration-500 ${
                  selectedFeatureID === feature.id
                    ? "font-bold text-xs lg:text-base"
                    : "font-normal text-sm lg:text-base"
                }`}
              >
                {parse(feature.name)}
              </span>
            </button>
          ))}
        </div>
      </div>
    )
  }

  // Centered layout with infinite scroll
  const slots = getSlotPositions()

  return (
    <div
      className={`w-full overflow-hidden mt-0 md:mt-0 ${className} relative`}
      ref={containerRef}
    >
      <div className="relative w-full h-[108px] lg:h-[140px]">
        {/* This is the container that centers everything */}
        <div className="absolute left-1/2 -translate-x-1/2 w-0 h-full">
          {/* Create a virtual list by rendering each feature multiple times if needed */}
          {features.map((feature, index) => {
            // Get position information including virtual positioning
            const position = getFeaturePosition(index)

            // Track this item for animation if it's wrapping
            if (position.isWrapping) {
              animatedItemsRef.current.add(feature.id)
            }

            // Skip rendering if the position is completely out of visible range and not wrapping
            if (position.opacity === 0 && !position.isWrapping) {
              return null
            }

            return (
              <motion.button
                key={feature.id}
                onClick={() => !isDragging && onFeatureSelect(feature.id)}
                className="absolute top-0 origin-center group flex flex-col items-center justify-between pt-2"
                style={{
                  width: `${itemWidth}px`,
                  pointerEvents: position.pointerEvents,
                }}
                initial={
                  position.isWrapping
                    ? {
                        // For wrapping items, start from their initial animation position
                        x: determineInitialPosition(position.x),
                        opacity: 0,
                        scale: 0.9,
                      }
                    : false
                }
                animate={{
                  // Position with offset to center the button and account for half the gap
                  x: position.x - itemWidth / 2,
                  opacity: position.opacity,
                  scale: position.scale,
                }}
                transition={{
                  duration: 0.3,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
              >
                <div
                  className={`flex items-center justify-center rounded-[12px] transform transition-all duration-500 ease-in-out ${
                    selectedFeatureID === feature.id
                      ? "bg-primary w-[46px] lg:w-14 2xl:w-16 h-[46px] lg:h-14 2xl:h-16"
                      : "w-10 lg:w-12 2xl:w-14 h-10 lg:h-12 2xl:h-14 group-hover:-translate-y-1 group-hover:bg-primary_lt"
                  }`}
                >
                  <DynamicSVG
                    url={feature.icon}
                    className={`transition-all duration-500 ease-in-out ${
                      selectedFeatureID === feature.id
                        ? "w-[34px] lg:w-10 2xl:w-12 h-[34px] lg:h-10 2xl:h-12 text-white"
                        : "w-7 lg:w-8 2xl:w-10 h-7 lg:h-8 2xl:h-10 text-primary"
                    }`}
                  />
                </div>
                <span
                  className={`pt-2 h-[34px] lg:h-[76px] text-primary transition-all duration-500 ${
                    selectedFeatureID === feature.id
                      ? "font-bold text-xs lg:text-base"
                      : "font-normal text-sm lg:text-base"
                  }`}
                >
                  {parse(feature.name)}
                </span>
              </motion.button>
            )
          })}

          {/* Render virtual duplicates for very short lists to ensure infinite scroll */}
          {features.length < visibleItems + 2 &&
            features.map((feature, index) => {
              // Create virtual duplicates with adjusted positions
              const virtualIndex = index + features.length
              const rawPosition = getItemPosition(index) - features.length

              // Only render if this would be in the visible range
              if (
                Math.abs(rawPosition) > Math.floor(visibleItems / 2) &&
                !animatedItemsRef.current.has(feature.id)
              ) {
                return null
              }

              // Get position with virtual offset
              const position = {
                x: slots[
                  Math.max(
                    -Math.floor(visibleItems / 2) - 1,
                    Math.min(Math.floor(visibleItems / 2) + 1, rawPosition)
                  )
                ].x,
                opacity:
                  Math.abs(rawPosition) <= Math.floor(visibleItems / 2)
                    ? slots[rawPosition].opacity
                    : 0,
                scale:
                  Math.abs(rawPosition) <= Math.floor(visibleItems / 2)
                    ? slots[rawPosition].scale
                    : 0.8,
                pointerEvents: isDragging ? "none" : "auto",
                isWrapping: animatedItemsRef.current.has(feature.id),
                wrapDirection:
                  lastScrollDirection || (rawPosition < 0 ? -1 : 1),
              }

              return (
                <motion.button
                  key={`virtual-${feature.id}-${virtualIndex}`}
                  onClick={() => !isDragging && onFeatureSelect(feature.id)}
                  className="h-[128px] lg:h-[128px] absolute top-0 origin-center group flex flex-col items-center justify-between pt-2"
                  style={{
                    width: `${itemWidth}px`,
                    pointerEvents: position.pointerEvents,
                  }}
                  initial={
                    position.isWrapping
                      ? {
                          // For wrapping items, start from their initial animation position
                          x: determineInitialPosition(position.x),
                          opacity: 0,
                          scale: 0.9,
                        }
                      : false
                  }
                  animate={{
                    // Position with offset to center the button and account for half the gap
                    x: position.x - itemWidth / 2,
                    opacity: position.opacity,
                    scale: position.scale,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: [0.43, 0.13, 0.23, 0.96],
                  }}
                >
                  <div
                    className={`flex items-center justify-center rounded-[12px] transform transition-all duration-500 ease-in-out group-hover:-translate-y-1 ${
                      selectedFeatureID === feature.id
                        ? "bg-primary w-[46px] lg:w-14 2xl:w-16 h-[46px] lg:h-14 2xl:h-16"
                        : "hover:bg-primary_lt w-10 lg:w-12 2xl:w-14 h-10 lg:h-12 2xl:h-14"
                    }`}
                  >
                    <DynamicSVG
                      url={feature.icon}
                      className={`transition-all duration-500 ease-in-out ${
                        selectedFeatureID === feature.id
                          ? "w-[34px] lg:w-10 2xl:w-12 h-[34px] lg:h-10 2xl:h-12 text-white"
                          : "w-7 lg:w-8 2xl:w-10 h-7 lg:h-8 2xl:h-10 text-primary"
                      }`}
                    />
                  </div>
                  <span
                    className={`h-[34px] lg:h-[48px] text-primary transition-all duration-500 ${
                      selectedFeatureID === feature.id
                        ? "font-bold text-xs lg:text-base"
                        : "font-normal text-sm lg:text-base"
                    }`}
                  >
                    {parse(feature.name)}
                  </span>
                </motion.button>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default FeatureTabs
