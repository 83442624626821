import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"

const ItemSlider = ({
  items,
  selectedItem,
  onItemSelect,
  targetHeight,
  cardDistance = 240, // New parameter to control distance between cards
}) => {
  // Track visual state for the carousel
  const [prevPosition, setPrevPosition] = useState({})
  const [dragging, setDragging] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    const updateDimensions = () => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }

    // Initialize dimensions
    updateDimensions()

    // Add resize listener
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  // Use currentIndex based on selectedItem
  const currentIndex =
    selectedItem && items?.length
      ? items.findIndex((item) => item.id === selectedItem.id)
      : 0

  // Early return if no items
  if (!items || items.length === 0) return null

  // Ensure we have a valid index
  const safeIndex = currentIndex >= 0 ? currentIndex : 0

  // Responsive value calculation function
  const getResponsiveValue = (value, screenWidth) => {
    if (typeof value === "object") {
      // Added buffer zones around breakpoints for smooth transitions
      if (screenWidth >= 1080)
        return value.lg || value.md || value.sm || value.default

      if (screenWidth >= 768) {
        // For screens just at/above the md breakpoint, interpolate values
        if (screenWidth < 840) {
          const progress = (screenWidth - 768) / (840 - 768)
          const smValue = value.sm || value.default
          const mdValue = value.md || value.sm || value.default
          return smValue + (mdValue - smValue) * progress
        }
        return value.md || value.sm || value.default
      }

      if (screenWidth >= 360) {
        // Interpolate between xs and sm
        if (screenWidth < 480) {
          const progress = (screenWidth - 360) / (480 - 360)
          const xsValue = value.xs || value.default
          const smValue = value.sm || value.xs || value.default
          return xsValue + (smValue - xsValue) * progress
        }
        return value.sm || value.xs || value.default
      }

      return value.xs || value.default
    }
    return value
  }

  // Define responsive measurements with breakpoints
  // Center card height is the primary dimension that everything else is based on
  const centerCardHeight = getResponsiveValue(targetHeight, windowWidth)

  // Set slider height to exactly match the center card height
  const sliderHeight = centerCardHeight

  // Container width calculation based on screen width
  const containerWidth = getResponsiveValue(
    {
      default: windowWidth,
      xs: windowWidth,
      sm: windowWidth,
      md: Math.min(1000, windowWidth * 0.9),
      lg: Math.min(1000, windowWidth * 0.9),
    },
    windowWidth
  )

  // Center card width is now always equal to height (1:1 aspect ratio)
  const centerWidth = centerCardHeight

  // Side card width as a proportion of center card width (70% of center width)
  const sideWidth = centerWidth * 0.7

  // Side card height (70% of center height)
  const sideCardHeight = centerCardHeight * 0.8

  // Calculate responsive card distance
  const responsiveCardDistance = getResponsiveValue(
    {
      default: cardDistance,
      xs: cardDistance * 1.2,
      sm: cardDistance * 1,
      md: cardDistance * 1.2,
      lg: cardDistance,
    },
    windowWidth
  )

  // Calculate side card positions relative to container center
  // Using component width/2 as the reference point for symmetry
  const sideCardLeft = -responsiveCardDistance
  const sideCardRight = responsiveCardDistance

  // Calculate offscreen positions based on the side positions
  const offscreenLeft = sideCardLeft * 2
  const offscreenRight = sideCardRight * 2

  // Now define positions from the center point of each card
  const slots = {
    1: {
      x: offscreenLeft,
      scale: 0.8,
      opacity: 0,
      blur: 8,
      width: sideWidth,
      height: sideCardHeight,
      zIndex: 0,
    },
    2: {
      x: sideCardLeft,
      scale: 1,
      opacity: 0.5,
      blur: 4,
      width: sideWidth,
      height: sideCardHeight,
      zIndex: 1,
    },
    3: {
      x: 0, // Center position
      scale: 1,
      opacity: 1,
      blur: 0,
      width: centerWidth,
      height: sliderHeight, // Exact match to container height
      zIndex: 2,
    },
    4: {
      x: sideCardRight,
      scale: 1,
      opacity: 0.5,
      blur: 4,
      width: sideWidth,
      height: sideCardHeight,
      zIndex: 1,
    },
    5: {
      x: offscreenRight,
      scale: 0.8,
      opacity: 0,
      blur: 8,
      width: sideWidth,
      height: sideCardHeight,
      zIndex: 0,
    },
  }

  const getItemPosition = (index) => {
    let position = (index - safeIndex + 3) % items.length
    if (position < 1) position += items.length
    if (position > 5) position -= items.length
    return position
  }

  const handleNext = () => {
    setPrevPosition(
      Object.fromEntries(items.map((_, i) => [i, getItemPosition(i)]))
    )
    const nextIndex = (safeIndex + 1) % items.length
    onItemSelect(items[nextIndex].id)
  }

  const handlePrev = () => {
    setPrevPosition(
      Object.fromEntries(items.map((_, i) => [i, getItemPosition(i)]))
    )
    const prevIndex = (safeIndex + items.length - 1) % items.length
    onItemSelect(items[prevIndex].id)
  }

  const renderSliderItems = () => {
    return items.map((item, index) => {
      const position = getItemPosition(index)
      const previousPosition = prevPosition[index]
      const slot = slots[position]

      if (!slot) return null

      const isEnteringOffscreen =
        (position === 1 && previousPosition !== 2) ||
        (position === 5 && previousPosition !== 4)

      // For proper centering of all items, adjust position calculation
      // This ensures all cards are positioned symmetrically from the component center
      const centerX = 0
      const cardWidth = slot.width
      const xPosition = centerX + slot.x - cardWidth / 2

      return (
        <motion.div
          key={item.id}
          className="absolute top-1/2 left-1/2"
          initial={
            isEnteringOffscreen
              ? {
                  x: slot.x,
                  y: "-50%",
                  scale: slot.scale,
                  opacity: slot.opacity,
                  filter: `blur(${slot.blur}px)`,
                  width: slot.width,
                  height: slot.height,
                  zIndex: slot.zIndex,
                }
              : false
          }
          animate={{
            x: xPosition,
            y: "-50%", // This keeps the item centered vertically
            scale: slot.scale,
            opacity: slot.opacity,
            filter: `blur(${slot.blur}px)`,
            width: slot.width,
            height: slot.height,
            zIndex: slot.zIndex,
          }}
          transition={
            isEnteringOffscreen
              ? {
                  duration: 0,
                }
              : {
                  duration: 0.5,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={item.image}
            alt={item.name}
            className="w-full h-full object-contain"
            draggable="false"
          />
        </motion.div>
      )
    })
  }

  return (
    <div
      className="w-full overflow-hidden"
      style={{
        height: `${sliderHeight}px`,
        position: "relative",
      }}
    >
      <div className="relative w-full h-full">
        <div className="px-8 md:px-[10%] absolute hidden sm:flex inset-x-0 top-1/2 -translate-y-1/2 items-center justify-between pointer-events-none z-30">
          <button
            onClick={handlePrev}
            className="z-20 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors pointer-events-auto"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            className="z-20 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors pointer-events-auto"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <motion.div
          className="relative w-full h-full mx-auto cursor-grab"
          style={{ maxWidth: `${containerWidth}px` }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          whileTap={{ cursor: "grabbing" }}
          onDragStart={() => {
            setDragging(true)
          }}
          onDrag={(event, info) => {
            const swipeThreshold = 50

            if (dragging) {
              if (info.offset.x > swipeThreshold) {
                handlePrev()
                setDragging(false)
              } else if (info.offset.x < -swipeThreshold) {
                handleNext()
                setDragging(false)
              }
            }
          }}
          onDragEnd={() => {
            setDragging(false)
          }}
        >
          {renderSliderItems()}
        </motion.div>
      </div>
    </div>
  )
}

export default ItemSlider
