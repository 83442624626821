import { useState, useEffect, useCallback, useRef, useMemo } from "react"
import CMSData from "aldoo-ra/CMS/cms-data"
import Value from "aldoo-ra/Value"
import Localization from "aldoo-ra/Localization"

const GROUP_ORDER = [
  "account_setup",
  "key_features",
  "advanced",
  "customizations",
]

const GROUP_TO_URL = {
  account_setup: "account-and-setup",
  advanced: "advanced-features",
  key_features: "key-features",
  customizations: "customizations",
}

const URL_TO_GROUP = Object.entries(GROUP_TO_URL).reduce(
  (acc, [group, url]) => {
    acc[url] = group
    return acc
  },
  {}
)

// Extract unique group IDs from features
const extractGroupIds = (features) => {
  const groupIds = new Set()
  features.forEach((feature) => {
    feature.group?.forEach((group) => {
      if (group.id) groupIds.add(group.id)
    })
  })
  const setToArray = Array.from(groupIds)

  // Return the groups in the order defined in GROUP_ORDER
  const filteredGroups = GROUP_ORDER.filter((group) =>
    setToArray.includes(group)
  )

  return filteredGroups.length > 0 ? filteredGroups : setToArray
}

export const useFeatureController = (options) => {
  const { skipURLSync = false } = options || {}

  const [platform] = Value("site_platform")
  const { currentLanguage } = Localization()

  // Refs to manage state and prevent update cycles
  const initializationRef = useRef({
    groupInitialized: false,
    featureInitialized: false,
    urlSyncEnabled: false,
  })

  // Platforms query
  const platformsQuery = CMSData({
    contentType: "AquaMailFeaturePlatform",
  })

  // Current platform ID
  const currentPlatformId = useMemo(() => {
    return platformsQuery.data?.find((p) => p.id === platform)?._id
  }, [platformsQuery.data, platform])

  // Features query - now using manual mode
  const featuresQuery = CMSData({
    contentType: "AquaMailFeature",
    match: {
      "content.platform._id": currentPlatformId,
    },
    manual: true,
  })

  // State management
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(() => {
    return ""
  })
  const [selectedFeature, setSelectedFeature] = useState(null)

  // Memoized features
  const processedFeatures = useMemo(
    () => featuresQuery.data || [],
    [featuresQuery.data]
  )

  // Initial data load effect
  useEffect(() => {
    if (currentPlatformId) {
      featuresQuery.refresh()
    }
  }, [currentPlatformId])

  // Language change effect - refreshes data
  useEffect(() => {
    if (currentPlatformId) {
      featuresQuery.refresh()
    }
  }, [currentLanguage, currentPlatformId])

  // Effect to refresh data when language changes
  useEffect(() => {
    if (currentPlatformId) {
      featuresQuery.refresh()
    }
  }, [currentLanguage, currentPlatformId])

  // Create a ref to track data updates with language context
  const processedDataRef = useRef(null)

  // Separate effect to update selectedFeature when data changes
  useEffect(() => {
    // Skip if no feature is selected or no data
    if (
      !selectedFeature ||
      !featuresQuery.data ||
      featuresQuery.data.length === 0
    )
      return

    // Find the same feature in the refreshed data
    const currentFeatureId = selectedFeature.id
    const updatedFeature = featuresQuery.data.find(
      (f) => f.id === currentFeatureId
    )

    if (JSON.stringify(selectedFeature) === JSON.stringify(updatedFeature))
      return

    if (updatedFeature) {
      setSelectedFeature(updatedFeature)
    } else if (selectedGroup) {
      const groupFeatures = featuresQuery.data
        .filter((f) => f.group?.some((g) => g.id === selectedGroup))
        .sort((a, b) => (a.priority || Infinity) - (b.priority || Infinity))

      if (groupFeatures.length > 0) {
        setSelectedFeature(groupFeatures[0])
      }
    }
  }, [featuresQuery.data, currentLanguage])

  // URL synchronization effect
  useEffect(() => {
    //if the user wants to skip the URL sync
    if (skipURLSync) return
    // Skip if URL sync is not enabled or no group or platform
    if (
      !initializationRef.current.urlSyncEnabled ||
      !selectedGroup ||
      !platform
    )
      return

    const urlSegment = GROUP_TO_URL[selectedGroup]
    if (!urlSegment) return

    // Must run in browser context
    if (typeof window === "undefined") return

    const currentPath = window.location.pathname

    // Reconstruct the path using the platform
    const newPath = `/${platform}/features/${urlSegment}`

    // Only update if the path is different
    if (currentPath !== newPath) {
      window.history.pushState({ group: selectedGroup }, "", newPath)
    }
  }, [selectedGroup, platform])

  // Extract groups effect
  useEffect(() => {
    if (processedFeatures.length > 0 && groups.length === 0) {
      const extractedGroups = extractGroupIds(processedFeatures)
      setGroups(extractedGroups)
    }
  }, [processedFeatures])

  // Group and feature initialization
  useEffect(() => {
    // Prevent multiple initializations
    if (initializationRef.current.groupInitialized) return

    // Ensure we have groups before initializing
    if (groups.length === 0) return

    // Validate initial group or use first group
    const validInitialGroup = groups[0]

    // Mark as initialized and set group
    initializationRef.current.groupInitialized = true
    setSelectedGroup(validInitialGroup)
  }, [groups])

  // Feature initialization based on group
  useEffect(() => {
    // Skip if no group or features are available or already initialized
    if (
      !selectedGroup ||
      processedFeatures.length === 0 ||
      initializationRef.current.featureInitialized
    )
      return

    // Find features for the current group
    const groupFeatures = processedFeatures
      .filter((feature) => feature.group?.some((g) => g.id === selectedGroup))
      .sort((a, b) => (a.priority || Infinity) - (b.priority || Infinity))

    // Select first feature if no feature is currently selected
    if (groupFeatures.length > 0 && !selectedFeature) {
      initializationRef.current.featureInitialized = true
      setSelectedFeature(groupFeatures[0])
    }
  }, [selectedGroup, processedFeatures, selectedFeature])

  // Initial URL detection
  useEffect(() => {
    if (
      typeof window === "undefined" ||
      initializationRef.current.urlSyncEnabled
    )
      return

    const currentPath = window.location.pathname
    const urlSegment = currentPath.split("/").pop()
    const groupFromUrl = URL_TO_GROUP[urlSegment]

    // Check if the URL corresponds to a valid group
    if (groupFromUrl && groups.includes(groupFromUrl)) {
      // Set the group from URL
      setSelectedGroup(groupFromUrl)

      // Select first feature of the group
      const groupFeatures = processedFeatures
        .filter((feature) => feature.group?.some((g) => g.id === groupFromUrl))
        .sort((a, b) => (a.priority || Infinity) - (b.priority || Infinity))

      if (groupFeatures.length > 0) {
        setSelectedFeature(groupFeatures[0])
      }

      // Enable URL sync after initial setup
      initializationRef.current.urlSyncEnabled = true
    }
  }, [groups, processedFeatures])

  // Helper to get features for a group
  const getFeaturesForGroup = useCallback(
    (group) => {
      return processedFeatures
        .filter((feature) => feature.group?.some((g) => g.id === group))
        .sort((a, b) => (a.priority || Infinity) - (b.priority || Infinity))
    },
    [processedFeatures]
  )

  // Custom setSelectedGroup to handle feature selection
  const customSetSelectedGroup = useCallback(
    (newGroup) => {
      // Resolve the new group (handle function or direct value)
      const resolvedGroup =
        typeof newGroup === "function" ? newGroup(selectedGroup) : newGroup

      // Only update if the group is different
      if (resolvedGroup !== selectedGroup) {
        // Enable URL sync
        initializationRef.current.urlSyncEnabled = true
        setSelectedGroup(resolvedGroup)
      }
    },
    [selectedGroup]
  )

  // Select feature by ID
  const selectFeatureByID = useCallback(
    (id) => {
      const feature = processedFeatures.find((f) => f.id === id)
      if (feature) {
        setSelectedFeature(feature)

        // Update group if necessary
        const featureGroup = feature.group?.[0]?.id
        if (featureGroup && groups.includes(featureGroup)) {
          // Enable URL sync
          initializationRef.current.urlSyncEnabled = true
          setSelectedGroup(featureGroup)
        }
      }
    },
    [processedFeatures, groups]
  )

  // Group navigation helpers
  const selectNextGroup = useCallback(() => {
    const currentIndex = groups.indexOf(selectedGroup)
    if (currentIndex < groups.length - 1) {
      // Enable URL sync
      initializationRef.current.urlSyncEnabled = true
      setSelectedGroup(groups[currentIndex + 1])
      //select the first feature of that group
      const groupFeatures = getFeaturesForGroup(groups[currentIndex + 1])
      if (groupFeatures.length > 0) {
        selectFeatureByID(groupFeatures[0].id)
      }
    }
  }, [groups, selectedGroup, getFeaturesForGroup, selectFeatureByID])

  const selectPrevGroup = useCallback(() => {
    const currentIndex = groups.indexOf(selectedGroup)
    if (currentIndex > 0) {
      // Enable URL sync
      initializationRef.current.urlSyncEnabled = true
      setSelectedGroup(groups[currentIndex - 1])
      //select the first feature of that group
      const groupFeatures = getFeaturesForGroup(groups[currentIndex - 1])
      if (groupFeatures.length > 0) {
        selectFeatureByID(groupFeatures[0].id)
      }
    }
  }, [groups, selectedGroup, getFeaturesForGroup, selectFeatureByID])

  // Memoized data retrieval
  const getData = useCallback(() => {
    if (!groups.length) return []

    const featuresSorted = []

    for (const group of groups) {
      const filteredFeatures = processedFeatures.filter((feature) =>
        feature.group?.some((g) => g.id === group)
      )

      // Sort by priority
      filteredFeatures.sort(
        (a, b) => (a.priority || Infinity) - (b.priority || Infinity)
      )

      featuresSorted.push(...filteredFeatures)
    }

    return featuresSorted
  }, [processedFeatures, groups])

  return {
    getData,
    groups,
    selectedGroup,
    setSelectedGroup: customSetSelectedGroup,
    selectedFeature,
    setSelectedFeature,
    selectFeatureByID,
    URL_TO_GROUP,
    GROUP_TO_URL,
    GROUP_ORDER,
    selectNextGroup,
    selectPrevGroup,
    features: processedFeatures,
    loading: featuresQuery.loading,
    getFeaturesForGroup,
    refreshFeatures: featuresQuery.refresh,
  }
}
